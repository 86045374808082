<template>
  <div v-show="isShow" class="dash_admin_box">
    <div style="display: block" class="dash_admin_box dash_commute_01">
      <!-- 검색 Wrap -->
      <div class="option_wrap" style="display: flex">
        <div class="search_filter">
          <form>
            <select
              v-model="selectSiteId"
              class="search_option"
              @change="changeSite()"
            >
              <option
                v-for="item in accessSiteList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
            <div class="option_date">
              <date-picker
                id="startedDate"
                v-model="listParam.beginDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
                @change="changeGraphData"
              />
              <span>~</span>
              <date-picker
                id="endedDate"
                v-model="listParam.endDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
                @change="changeGraphData"
              />
            </div>

            <select
              v-show="displayList === true"
              v-model="firstCode"
              class="search_option"
              @change="codeChange()"
            >
              <option
                v-for="item in testItemList"
                :key="item.code"
                :value="item.code"
              >
                {{ item.name }}
              </option>
            </select>

            <div class="option_date w100" v-show="displayList === true">
              <input
                v-model="listParam.name"
                type="text"
                placeholder="확인자/감독관 검색"
                @keyup.enter="onClickSearch"
              />
            </div>

            <div class="search_button" v-show="displayList === true">
              <button type="button" @click="onClickSearch">검색</button>
            </div>
          </form>
        </div>
        <div class="right_area">
          <div class="download_button" v-show="displayList === true">
            <button type="button" @click="onClickExcel">엑셀 다운로드</button>
          </div>
        </div>
      </div>

      <div class="option_wrap">
        <div class="search_filter"></div>
        <div class="right_area">
          <div
            class="download_button"
            :class="displayList === true ? 'on' : ''"
          >
            <button type="button" @click="changeDisplay('list')">성적서</button>
          </div>
          <div
            class="download_button"
            :class="
              graphTypeCount === true && displayList === false ? 'on' : ''
            "
          >
            <button type="button" @click="changeGraphType('count')">
              그래프
            </button>
          </div>
          <div
            class="download_button"
            :class="
              graphTypeMonth === true && displayList === false ? 'on' : ''
            "
          >
            <button type="button" @click="changeGraphType('month')">
              월별추이
            </button>
          </div>
        </div>
      </div>
      <!-- 검색 Wrap //-->
      <div
        v-show="displayGraph === true && graphTypeCount === true"
        id="container"
        class="graph_wrap oneitem"
        @click="clickCountChart"
      >
        <v-chart
          class="chart"
          :option="option"
          autoresize
          @click="clickCountChart"
        />
      </div>

      <div
        v-show="displayGraph === true && graphTypeCount === true"
        class="dash_admin_box_table"
        style="height: 100%"
      >
        <table>
          <thead>
            <tr>
              <th></th>
              <th v-for="(xAxisData, index) in option.xAxis.data" :key="index">
                {{ xAxisData }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(legendData, legendIndex) in option.legend.data"
              :key="legendIndex"
            >
              <td>{{ legendData }}</td>
              <td
                v-for="(seriesData, seriesIndex) in option.series[legendIndex]
                  .data"
                :key="seriesIndex"
              >
                {{ addCommasToNumber(checkData(seriesData)) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        v-show="displayGraph === true && graphTypeMonth === true"
        id="container"
        class="graph_wrap oneitem"
        @click="clickMonthChart"
      >
        <v-chart
          class="chart"
          :option="optionMonth"
          autoresize
          @click="clickMonthChart"
        />
      </div>

      <div
        v-show="displayGraph === true && graphTypeMonth === true"
        class="dash_admin_box_table"
        style="height: 100%"
      >
        <table>
          <thead>
            <tr>
              <th></th>
              <th
                v-for="(xAxisData, index) in optionMonth.xAxis.data"
                :key="index"
              >
                {{ xAxisData }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(legendData, legendIndex) in optionMonth.legend.data"
              :key="legendIndex"
            >
              <td>{{ legendData }}</td>
              <td
                v-for="(seriesData, seriesIndex) in optionMonth.series[
                  legendIndex
                ].data"
                :key="seriesIndex"
              >
                {{ addCommasToNumber(checkData(seriesData)) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-show="displayList === true" class="dash_admin_box_table">
        <table>
          <thead>
            <tr>
              <th width="90">처리상태</th>
              <th width="90">현장명</th>
              <th>시험일</th>
              <th>시험번호</th>
              <th>시험구분</th>
              <th width="280">위치/부위</th>
              <th width="200">재료</th>
              <th>시험·검사항목</th>
              <th>시험성과</th>
              <th width="150">시험기준</th>
              <th width="100">판정</th>
              <th>확인자</th>
              <th>서명</th>
              <th>감독관</th>
              <th>서명</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="pageParam.total === 0">
              <td colspan="15">검색 결과가 없습니다.</td>
            </tr>
            <template v-if="pageParam.total !== 0">
              <tr v-for="(item, index) in pageParam.content" :key="index">
                <td>
                  {{ displayStatus(item.bookStatus) }}
                </td>
                <td>
                  {{ item.siteName }}
                </td>
                <td>
                  {{ item.testDay }}
                </td>
                <td style="cursor: pointer; color: aqua" @click="detail(item)">
                  {{ item.testNo }}
                </td>
                <td>
                  {{ getTestTypeValue(item.division) }}
                </td>
                <td v-html="enter2Br(item.location)" />
                <td>
                  {{ item.material }}
                </td>
                <td>
                  {{ item.testItem.name }}
                </td>
                <td>
                  {{ item.result }}
                </td>
                <td>
                  {{ item.standard }}
                </td>
                <td>
                  {{ displayJudgment(item.judgment) }}
                </td>
                <td>
                  <span> {{ item.checkerName }}</span>
                </td>
                <td>
                  <span class="sign">
                    <img
                      v-if="
                        item.checkerFileId != null && item.checkerSignDt != null
                      "
                      :src="
                        FILE_URL +
                        'file_id=' +
                        item.checkerFileId +
                        '&file_no=2'
                      "
                      alt=""
                    />
                  </span>
                </td>
                <td>
                  <span> {{ item.supervisorName }}</span>
                </td>
                <td>
                  <span class="sign">
                    <img
                      v-if="
                        item.supervisorFileId != null &&
                        item.supervisorSignDt != null
                      "
                      :src="
                        FILE_URL +
                        'file_id=' +
                        item.supervisorFileId +
                        '&file_no=2'
                      "
                      alt=""
                    />
                  </span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <pagination
        v-show="displayList === true"
        :page-param="pageParam"
        :list-cnt="listParam.size"
        @pageCallback="pageCallback"
      />
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import Pagination from "./layout/Pagination.vue";
import { getToday, toDayFormatAddDay, dayFormatNewDate } from "../utils/date";
import { mapGetters, mapActions, mapState } from "vuex";
import TestItemApi from "../api/qbox/TestItemApi";
import TestBookApi from "../api/qbox/TestBookApi";
import SiteApi from "../api/qbox/SiteApi";

import PlanCountApi from "../api/qbox/PlanCountApi";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import PopupModifyNoticeVue from "./detail/PopupCreateNotice.vue";
import { QBOX_URL, FILE_URL, JWT_TOKEN } from "../const/index";
import getters from "@/store/modules/common/getters";
use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  BarChart,
]);

export default {
  name: "TestBookList",
  components: { Pagination, DatePicker, VChart, PopupModifyNoticeVue },
  props: {
    siteId: {
      type: Number,
      default: null,
    },
    tabId: {
      type: Number,
      default: 1,
    },
  },
  data: () => {
    return {
      companyList: [],
      pageParam: { content: [] },
      listParam: {
        page: 1,
        size: 20,
        beginDate: toDayFormatAddDay(-7),
        endDate: getToday(),
        name: null,
      },
      testItemList: {},
      firstCode: null,
      accessSiteList: [],
      selectSiteId: null,
      siteYearPlanList: [],
      testItemDepth1: null,
      option: {},
      optionMonth: {},
      testItemName: [],
      testItemTotal: [],
      testItemMonth: [],
      testItemPlanCount: [],
      displayGraph: false,
      displayList: true,
      graphTypeCount: true,
      graphTypeMonth: false,
      isShow: false,
      FILE_URL: FILE_URL,
      siteType: "",
      codeNameList: [],
    };
  },
  computed: {
    ...mapGetters(["getLang"]),
    ...mapState({}),
  },
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    ...mapActions(["showLoading", "hideLoading"]),
    dayFormatNewDate: dayFormatNewDate,
    async init() {
      this.selectSiteId = this.siteId;
      const response = await SiteApi.getSiteYearPlan(this.siteId);
      this.siteYearPlanList = response;
      if (this.siteYearPlanList.length > 0) {
        const today = this.getToday();
        const findSiteYear = this.siteYearPlanList.filter(
          (siteYear) => siteYear.beginDay <= today && siteYear.endDay >= today
        );
        if (findSiteYear.length > 0) {
          this.listParam.beginDate = findSiteYear[0].beginDay;
        } else {
          this.listParam.beginDate = toDayFormatAddDay(-7);
        }
      } else {
        const today = new Date();
        this.listParam.beginDate = this.dayFormatNewDate(
          new Date(today.getFullYear(), 0, 1)
        );
      }
      this.mainStatistic();
      this.monthStatistic();
      this.getSiteList();
      this.fetchTestBook();
      this.testItem();
    },
    getToday: getToday,
    async getSiteList() {
      const response = await SiteApi.getSiteList();
      this.accessSiteList = response;
      this.siteType = response[0].type;
    },
    onClickSearch() {
      this.listParam.page = 1;
      this.fetchTestBook();
    },
    testItem() {
      TestItemApi.getDepthList(1, this.siteId).then((res) => {
        this.testItemList = res;
        const defaultCode = { name: "공종전체", id: null, code: null };
        this.testItemList.unshift(defaultCode);
      });
    },
    changeSite() {
      this.fetchTestBook();
      this.monthStatistic();
      this.mainStatistic();
    },
    async fetchTestBook() {
      //TODO 구해야 됨
      this.listParam.siteId = this.selectSiteId;

      this.listParam.itemCode = this.firstCode;
      const response = await TestBookApi.testBookList(this.listParam);
      response.size = this.listParam.size;
      this.pageParam = response;
      this.pageParam.content.forEach((item) => {
        this.accessSiteList.forEach((site) => {
          if (item.siteId === site.id) {
            item.siteName = site.name;
          }
        });
      });
    },
    pageCallback(page) {
      this.listParam.page = page;
      this.fetchTestBook();
    },
    contentSubStr(str) {
      if (str) {
        str = str.replace(/<[^>]*>?/g, "");
        if (str.length > 10) {
          str = str.substr(0, 8) + "...";
        }
      }
      return str;
    },
    imgFaults(item) {
      var url = "/img/common/";
      if (item) url += "bullet_red_icon.png";
      else url += "bullet_green_icon.png";

      return url;
      //bullet_green_icon.png
      //bullet_red_icon.png
    },
    async onClickExcel() {
      const url = "/testBooks/excel";
      this.showLoading();
      await TestBookApi.excelDown(url, this.listParam);
      this.hideLoading();
    },
    getNumber(index) {
      this.pageParam.total -
        ((this.listParam.page - 1) * this.listParam.size + index);
    },
    codeChange() {
      this.pageParam = { content: [] };
      this.listParam.itemCode = this.firstCode;
      this.fetchTestBook();
    },
    displayJudgment(judgment) {
      if (judgment === "pass") {
        return "합격";
      } else if (judgment === "fail") {
        return '<font color="red">불합격</font>';
      }
      return "-";
    },
    displayStatus(status) {
      if (status === "approval") return "결재완료";
      else return "결재대기";
    },
    getTestTypeValue(division) {
      if (division === "remicon") return "레미콘시험";
      else if (division === "inside") return "현장시험";
      else if (division === "request") return "의뢰시험";
      else return "-";
    },
    enter2Br(str) {
      if (!str) return "";
      return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
    async mainStatistic() {
      const response = await PlanCountApi.mainStatistic(
        this.selectSiteId,
        this.listParam
      );
      this.testItemDepth1 = response;

      this.testItemName = [];
      this.testItemMonth = [];
      this.testItemTotal = [];
      this.testItemPlanCount = [];

      for (var item of this.testItemDepth1) {
        if (item.name === "civil") item.name = "토목";
        if (item.name === "construct") item.name = "건축";
        this.testItemName.push(item.name);
        this.testItemMonth.push(item.monthCount);
        this.testItemTotal.push({ value: item.totalCount, id: item.id });
        this.testItemPlanCount.push(item.planCount);
      }

      this.setup();
    },
    async monthStatistic() {
      this.codeNameList = [];
      const response = await PlanCountApi.monthlyCount(
        this.selectSiteId,
        this.listParam
      );
      response.forEach((item) => {
        this.codeNameList.push({ name: item.name, id: item.id });
      });
      this.codeNameList = [
        ...new Set(this.codeNameList.map(JSON.stringify)),
      ].map(JSON.parse);
      console.log(this.codeNameList);

      var xAxisData = [];
      var codeName = [];
      var seriesData = [];
      for (var item of response) {
        if (item.month) {
          xAxisData.push(item.month);
        }
        if (item.name === "construct") item.name = "건축";
        if (item.name === "civil") item.name = "토목";
        codeName.push(item.name);
      }

      const newXAxisData = xAxisData.filter(
        (v, i) => xAxisData.indexOf(v) === i
      );
      const newCodeName = codeName.filter((v, i) => codeName.indexOf(v) === i);

      for (let i = 0; i < newCodeName.length; i++) {
        seriesData.push({
          triggerLineEvent: true,
          name: newCodeName[i],
          type: "line",
          // stack: "Total",
          // areaStyle: {},
          emphasis: {
            focus: "series",
          },

          data: [],
        });
      }

      for (let i = 0; i < newCodeName.length; i++) {
        for (var data of response) {
          if (seriesData[i].name === data.name) {
            seriesData[i].data.push({ value: data.monthCount, id: data.id });
          }
        }
      }

      this.setupMonth(newXAxisData, newCodeName, seriesData);
    },
    changeDisplay(type) {
      if (type === "graph") {
        this.displayGraph = true;
        this.displayList = false;
      } else if (type === "list") {
        this.displayGraph = false;
        this.displayList = true;
      }
    },
    changeGraphType(type) {
      // this.changeDisplay("graph");
      this.displayGraph = true;
      this.displayList = false;
      if (type === "count") {
        this.graphTypeCount = true;
        this.graphTypeMonth = false;
      } else if (type === "month") {
        this.graphTypeCount = false;
        this.graphTypeMonth = true;
      }
    },
    setup() {
      let legendData = [];
      if (this.siteType === "CIVIL") {
        legendData = ["계획", "누계", "금월"];
      } else {
        legendData = ["누계", "금월"];
      }
      this.option = {
        grid: {
          top: 40,
          bottom: 50,
          left: 50,
          right: 0,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        xAxis: {
          type: "category",
          axisPointer: {
            type: "shadow",
          },
          data: this.testItemName,
          boundaryGap: true,
          axisLabel: {
            rotate: 20,
            interval: 0,
            // X축 폰트 크기
            show: true,
            fontSize: 8,
            // color: "rgba(255, 255, 255, 0.5)",
            color: "#8D8D8D",
            textStyle: {
              fontSize: 12,
              fontWeight: "bolder",
            },
          },
          axisLine: {
            // X축 라인 컬러
            lineStyle: {
              color: "rgba(255, 255, 255, 0.2)",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            // Y축 폰트 크기
            fontSize: 11,
            color: "rgba(255, 255, 255, 0.5)",
          },
          splitLine: {
            // 차트 중간선 컬러
            lineStyle: {
              color: "rgba(255, 255, 255, 0.1)",
            },
          },
        },
        legend: {
          data: legendData,
          textStyle: {
            color: "#ccc",
            fontSize: 20,
            fontFamily: "sans-serif",
          },
        },
        series: [
          {
            data: this.testItemPlanCount,
            type: "line",
            name: "계획",
            barWidth: "10%",
            showBackground: true,
            emphasis: {
              focus: "series",
            },
            // areaStyle: {
            //   opacity: 0.3,
            // },
          },
          {
            data: this.testItemTotal,
            type: "bar",
            name: "누계",
            barWidth: "10%",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.15)",
            },
            itemStyle: {
              color: "rgba(100, 255, 1, 0.6)",
            },
            emphasis: {
              focus: "series",
            },
            stack: "x",
          },
          {
            data: this.testItemMonth,
            type: "bar",
            name: "금월",
            barWidth: "10%",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.15)",
            },
            itemStyle: {
              color: "rgba(185, 80, 245, 0.6)",
            },
            emphasis: {
              focus: "series",
            },
            stack: "x",
          },
        ],
      };
      console.log(this.option);
    },

    setupMonth(xAxisData, codeName, seriesData) {
      this.optionMonth = {
        grid: {
          top: 40,
          bottom: 50,
          left: 50,
          right: 0,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        xAxis: {
          type: "category",
          axisPointer: {
            type: "shadow",
          },
          data: xAxisData,
          boundaryGap: true,
          axisLabel: {
            rotate: 20,
            interval: 0,
            // X축 폰트 크기
            show: true,
            fontSize: 8,
            // color: "rgba(255, 255, 255, 0.5)",
            color: "#8D8D8D",
            textStyle: {
              fontSize: 12,
              fontWeight: "bolder",
            },
          },
          axisLine: {
            // X축 라인 컬러
            lineStyle: {
              color: "rgba(255, 255, 255, 0.2)",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            // Y축 폰트 크기
            fontSize: 11,
            color: "rgba(255, 255, 255, 0.5)",
          },
          splitLine: {
            // 차트 중간선 컬러
            lineStyle: {
              color: "rgba(255, 255, 255, 0.1)",
            },
          },
        },
        legend: {
          data: codeName,
          textStyle: {
            color: "#ccc",
            fontSize: 20,
            fontFamily: "sans-serif",
          },
        },
        series: seriesData,
      };
      console.log(this.optionMonth);
    },
    open() {
      this.init();
      this.isShow = true;
    },
    close() {
      this.listParam.beginDate = toDayFormatAddDay(-7);
      this.listParam.endDate = getToday();
      this.listParam.name = null;
      this.isShow = false;
    },
    detail(item) {
      var popupW = 1200;
      var popupH = 800;
      var left = Math.ceil((window.screen.width - popupW) / 2);
      var top = Math.ceil((window.screen.height - popupH) / 2);
      const token = localStorage.getItem(JWT_TOKEN);
      const options =
        "toolbar=no,scrollbars=no,resizable=yes,status=no,menubar=no,width=1200, height=800, top=" +
        top +
        ",left=" +
        left;
      window.open(
        `${QBOX_URL}/testBookRouter/${item.id}?isPopup=1&token=${token}`,
        "_blank",
        options
      );
    },
    changeGraphData() {
      this.mainStatistic();
      this.monthStatistic();
    },
    async clickCountChart(param) {
      if (param.componentType) {
        param.event.event.stopPropagation(); //상위 태그로 클릭이벤트 전달을 막아줌
        this.listParam.testItemId = param.data.id;
        const result = await PlanCountApi.mainStatisticByDepth(
          this.selectSiteId,
          this.listParam
        );
        if (!result) {
          this.$toast.info("하위 종목 데이터가 없습니다");
          return;
        }

        let testItemName = [];
        let testItemMonth = [];
        let testItemTotal = [];
        let testItemPlanCount = [];

        for (var item of result) {
          testItemName.push(item.name);
          testItemMonth.push(item.monthCount);
          testItemTotal.push({ value: item.totalCount, id: item.id });
          testItemPlanCount.push(item.planCount);
        }
        this.option = {
          grid: {
            top: 40,
            bottom: 50,
            left: 50,
            right: 0,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
            },
          },
          xAxis: {
            type: "category",
            axisPointer: {
              type: "shadow",
            },
            data: testItemName,
            boundaryGap: true,
            axisLabel: {
              rotate: 20,
              interval: 0,
              // X축 폰트 크기
              show: true,
              fontSize: 8,
              // color: "rgba(255, 255, 255, 0.5)",
              color: "#8D8D8D",
              textStyle: {
                fontSize: 12,
                fontWeight: "bolder",
              },
            },
            axisLine: {
              // X축 라인 컬러
              lineStyle: {
                color: "rgba(255, 255, 255, 0.2)",
              },
            },
          },
          yAxis: {
            type: "value",
            axisLabel: {
              // Y축 폰트 크기
              fontSize: 11,
              color: "rgba(255, 255, 255, 0.5)",
            },
            splitLine: {
              // 차트 중간선 컬러
              lineStyle: {
                color: "rgba(255, 255, 255, 0.1)",
              },
            },
          },
          legend: {
            data: ["계획", "누계", "금월"],
            textStyle: {
              color: "#ccc",
              fontSize: 20,
              fontFamily: "sans-serif",
            },
          },
          series: [
            {
              data: testItemPlanCount,
              type: "line",
              name: "계획",
              barWidth: "10%",
              showBackground: true,
              emphasis: {
                focus: "series",
              },
              areaStyle: {
                opacity: 0.3,
              },
            },
            {
              data: testItemTotal,
              type: "bar",
              name: "누계",
              barWidth: "10%",
              showBackground: true,
              backgroundStyle: {
                color: "rgba(180, 180, 180, 0.15)",
              },
              itemStyle: {
                color: "rgba(100, 255, 1, 0.6)",
              },
              emphasis: {
                focus: "series",
              },
              stack: "x",
            },
            {
              data: testItemMonth,
              type: "bar",
              name: "금월",
              barWidth: "10%",
              showBackground: true,
              backgroundStyle: {
                color: "rgba(180, 180, 180, 0.15)",
              },
              itemStyle: {
                color: "rgba(185, 80, 245, 0.6)",
              },
              emphasis: {
                focus: "series",
              },
              stack: "x",
            },
          ],
        };

        console.log(param);
        console.log(result);
        if (!result) {
          console.log("데이터 없음");
        }
      } else {
        this.mainStatistic();
        this.monthStatistic();
        console.log("배경클릭");
      }
    },
    async clickMonthChart(param) {
      if (param.componentType) {
        param.event.event.stopPropagation(); //상위 태그로 클릭이벤트 전달을 막아줌
        if (param.data) {
          this.listParam.testItemId = param.data.id;
        } else {
          //라인 자체를 클릭하면 id를 받을수 없어서 다음과 같이 처리함
          this.codeNameList.forEach((item) => {
            if (param.seriesName === item.name)
              this.listParam.testItemId = item.id;
          });
        }
        console.log(param);

        const response = await PlanCountApi.monthlyCountByDepth(
          this.selectSiteId,
          this.listParam
        );

        if (!response) {
          this.$toast.info("하위 종목 데이터가 없습니다");
          return;
        }

        response.forEach((item) => {
          this.codeNameList.push({ name: item.name, id: item.id });
        });
        this.codeNameList = [
          ...new Set(this.codeNameList.map(JSON.stringify)),
        ].map(JSON.parse);

        var xAxisData = [];
        var codeName = [];
        var seriesData = [];
        for (var item of response) {
          if (item.month) {
            xAxisData.push(item.month);
          }
          if (item.name === "construct") item.name = "건축";
          if (item.name === "civil") item.name = "토목";
          codeName.push(item.name);
        }

        const newXAxisData = xAxisData.filter(
          (v, i) => xAxisData.indexOf(v) === i
        );
        const newCodeName = codeName.filter(
          (v, i) => codeName.indexOf(v) === i
        );

        for (let i = 0; i < newCodeName.length; i++) {
          seriesData.push({
            triggerLineEvent: true,
            name: newCodeName[i],
            type: "line",
            // stack: "Total",
            // areaStyle: {},
            emphasis: {
              focus: "series",
            },

            data: [],
          });
        }

        for (let i = 0; i < newCodeName.length; i++) {
          for (var data of response) {
            if (seriesData[i].name === data.name) {
              seriesData[i].data.push({ value: data.monthCount, id: data.id });
            }
          }
        }

        this.setupMonth(newXAxisData, newCodeName, seriesData);
      } else {
        this.mainStatistic();
        this.monthStatistic();
        console.log("배경클릭");
      }
    },
    checkData(item) {
      if (!item) return 0;
      if (typeof item === "object") {
        return item.value;
      }
      return item;
    },
    addCommasToNumber(number) {
      if (!number) return 0;
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
<style scoped>
.option_wrap {
  display: block;
}
/* .graph_wrap {
  height: calc(100vh - 250px) !important;
} */
.graph_wrap.oneitem {
  height: calc(100vh - 450px) !important;
}
/* .dash_admin_box_table {
  height: 100%;
} */
</style>
