<template>
  <div
    :style="{ display: isShow ? 'block' : 'none' }"
    class="popup modify_notice"
  >
    <div class="popup_header">
      <div class="header_title">한중 콘크리트 온도일지 상세</div>
      <button class="close_popup">
        <img src="/img/common/popup_close.svg" alt="팝업 닫기" @click="close" />
      </button>
    </div>
    <div class="foot_btns" style="justify-content: flex-start">
      <button
        type="button"
        class="cancel"
        :class="detailShow === true ? 'on' : ''"
        @click="changeView('list')"
        style="border: 1px solid #0064ff"
      >
        상세 내용
      </button>
      <button
        type="button"
        class="cancel"
        :class="detailGraph === true ? 'on' : ''"
        @click="changeView('graph')"
        style="border: 1px solid #0064ff"
      >
        그래프
      </button>
      <button
        type="button"
        class="cancel"
        :class="fileListShow === true ? 'on' : ''"
        @click="changeView('file')"
        style="border: 1px solid #0064ff"
      >
        첨부 파일
      </button>
    </div>

    <div class="popup_content">
      <div class="form_wrap">
        <div
          class="form_item"
          v-show="detailShow === true"
          style="height: 400px; overflow-y: scroll"
        >
          <div class="form_write">
            <p class="title">현장명</p>
            <p class="input">
              <input
                v-model="coldConcBookDetail.siteName"
                type="text"
                readonly
              />
            </p>
            <p class="title" style="padding-left: 20px">타설일자</p>
            <p class="input">
              <input
                v-model="coldConcBookDetail.pourDay"
                type="text"
                readonly
              />
            </p>
          </div>
          <div class="form_write">
            <p class="title">타설부위</p>
            <p class="input">
              <input
                v-model="coldConcBookDetail.location"
                type="text"
                readonly
              />
            </p>
            <p class="title" style="padding-left: 20px">양생방법</p>
            <p class="input">
              <input v-model="coldConcBookDetail.curing" type="text" readonly />
            </p>
          </div>
          <div class="form_write">
            <p class="title">규격</p>
            <p class="input">
              <input
                v-model="coldConcBookDetail.specification"
                type="text"
                readonly
              />
            </p>
            <p class="title" style="padding-left: 20px">타설량</p>
            <p class="input">
              <input
                v-model="coldConcBookDetail.quantity"
                type="text"
                readonly
              />
            </p>
          </div>
          <div class="form_write">
            <p class="title">양생기간-총일</p>
            <p class="input">
              <input
                v-model="coldConcBookDetail.curingPeriod"
                type="text"
                readonly
              />
            </p>
            <p class="title" style="padding-left: 20px">타설시간</p>
            <p class="input">
              <input v-model="coldConcBookDetail.time" type="text" readonly />
            </p>
          </div>
          <div class="form_write">
            <p class="title">양생기간-증기</p>
            <p class="input">
              <input
                v-model="coldConcBookDetail.curingPeriodSteam"
                type="text"
                readonly
              />
            </p>
            <p class="title" style="padding-left: 20px">양생기간-보온</p>
            <p class="input">
              <input
                v-model="coldConcBookDetail.curingPeriodWarm"
                type="text"
                readonly
              />
            </p>
          </div>
          <div class="form_write">
            <p class="title">Con'c(생산) &nbsp;&nbsp;&nbsp;최저/최고</p>
            <p class="input">
              <input
                v-model="coldConcBookDetail.concCreteTemp"
                type="text"
                readonly
              />
            </p>
            <p class="title" style="margin-left: 10px">
              Con'c(타설) &nbsp;&nbsp;&nbsp;최저/최고
            </p>
            <p class="input">
              <input
                v-model="coldConcBookDetail.concPourTemp"
                type="text"
                readonly
              />
            </p>
          </div>
          <div class="form_write">
            <p class="title">
              대기 온도 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 최저/최고
            </p>
            <p class="input">
              <input
                v-model="coldConcBookDetail.airTemp"
                type="text"
                readonly
              />
            </p>
            <p class="title" style="margin-left: 20px">압축강도</p>
            <p class="input">
              <input
                v-model="coldConcBookDetail.strength"
                type="text"
                readonly
              />
            </p>
          </div>
        </div>

        <div id="container" v-show="detailGraph === true">
          <v-chart class="chart" :option="option" autoresize />
        </div>

        <div
          v-show="fileListShow === false"
          class="dash_admin_box_table"
          style="height: 400px; overflow-y: scroll"
        >
          <table>
            <thead>
              <tr>
                <th width="40">일자</th>
                <th width="70">측정시간</th>
                <th width="70" colspan="2">양생온도</th>
                <th width="70">대기온도</th>
                <th width="70">점검자</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="coldConcExcelData.length === 0">
                <td colspan="5">등록된 결과가 없습니다.</td>
              </tr>
              <template v-if="coldConcExcelData.length !== 0">
                <tr v-for="(item, index) in coldConcExcelData" :key="index">
                  <td>
                    {{ item.day }}
                  </td>
                  <td>
                    {{ item.time }}
                  </td>

                  <td>
                    {{ item.curingTemp }}
                  </td>
                  <td>
                    {{ item.curingTempSec }}
                  </td>
                  <td>
                    {{ item.airTemp }}
                  </td>
                  <td>
                    {{ item.regName }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>

        <div
          class="form_write"
          style="display: contents"
          v-show="fileListShow === true"
        >
          <p
            class="title"
            style="margin-left: 350px; margin-top: 20px; margin-bottom: 20px"
          >
            첨부파일 목록
          </p>
          <div class="foot_btns" v-show="etcFiles.length !== 0">
            <button
              type="button"
              class="cancel"
              @click="allFileDown"
              style="border: 1px solid #0064ff"
            >
              전체 받기
            </button>
          </div>

          <div
            class="form_write"
            style="display: block; height: 400px; overflow-y: scroll"
          >
            <p class="input" v-if="etcFiles.length === 0">
              <input v-model="msg" type="text" readonly />
            </p>
            <p
              class="input"
              v-for="file in etcFiles"
              :key="file.fileNo"
              @click="fileDown(file)"
              download
            >
              <input
                :value="getFileInfo(file)"
                type="text"
                readonly
                style="cursor: pointer"
              />
              <span
                v-if="file.fileExtension === 'pdf'"
                class="pdf-btn"
                @click.stop="viewPdf(file)"
                >Viewer
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="popup_footer">
      <div class="foot_btns">
        <button type="button" class="cancel" @click="close">닫기</button>
      </div>
    </div>
  </div>
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  MarkLineComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import HistoryFileApi from "../../api/qbox/HistoryFileApi";
import ColdConcBookApi from "../../api/qbox/ColdConcBookApi";
use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  BarChart,
  MarkLineComponent,
]);
export default {
  name: "PopupWeatherConcDetail",
  components: { VChart },
  props: {},
  data: () => {
    return {
      isShow: false,
      qualityPlan: {
        siteName: "3공구",
        day: "2024-05-11",
        location: "후리교 우수관 기초",
        quantity: "50",
        specification: "25-30-150",
        company: "삼정레미콘(주)",
        type: "보온 , 급열(증기)",
        time: "13:57 ~ 15:47",
        concTemp: "20",
        airTemp: "15 / 10",
      },
      msg: "등록된 파일이 없습니다",
      titleMsg: "",
      coldConcBookDetail: {},
      coldConcExcelData: [],
      etcFiles: [],
      detailShow: true,
      fileListShow: false,
      detailGraph: false,
      option: {},
    };
  },
  computed: {},
  watch: {},
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    close() {
      this.detailShow = true;
      this.fileListShow = false;
      this.detailGraph = false;
      this.isShow = false;
    },
    async open(item) {
      this.etcFiles = [];
      this.coldConcBookDetail = item;
      if (item.beginTime && item.endTime)
        this.coldConcBookDetail.time = item.beginTime + "~" + item.endTime;
      if (item.concCreateTempMin || item.concPourTempMax)
        this.coldConcBookDetail.concCreteTemp =
          item.concCreateTempMin + " / " + item.concCreateTempMax;
      if (item.concPourTempMax || item.concPourTempMin)
        this.coldConcBookDetail.concPourTemp =
          item.concPourTempMin + " / " + item.concPourTempMax;
      if (item.airTempMax || item.airTempMin)
        this.coldConcBookDetail.airTemp =
          item.airTempMin + " / " + item.airTempMax;
      const response = await ColdConcBookApi.concBookExcelData(item.id);
      this.coldConcExcelData = response;
      console.log(response);

      let xAxisData = [];
      let yAxisData = [];

      response.forEach((item) => {
        yAxisData.push(item.curingTemp);
        yAxisData.push(item.curingTempSec);
      });
      yAxisData.forEach((item, index) => {
        xAxisData.push(index + 1);
      });
      console.log(yAxisData);

      if (item.fileId) {
        ColdConcBookApi.getFiles(item.fileId).then((res) => {
          this.etcFiles = res.content;
        });
      }

      this.setup(xAxisData, yAxisData);
      this.isShow = true;
    },

    fileDown(file) {
      HistoryFileApi.fileDown(file.name, file.fileId, file.fileNo);
    },
    async allFileDown() {
      const fileArray = [];

      this.etcFiles.forEach((item) => {
        const file = {};
        file.fileId = item.fileId;
        file.fileNo = item.fileNo;
        fileArray.push(file);
      });

      const fileName = "관련문서";
      await HistoryFileApi.multiDownload(fileArray, fileName);
    },
    viewPdf(file) {
      this.$emit("viewPdf", file);
    },
    getFileInfo(file) {
      return file.name + "(" + (file.size * 0.000001).toFixed(2) + "MB)";
    },
    changeView(type) {
      if (type === "list") {
        this.detailShow = true;
        this.fileListShow = false;
        this.detailGraph = false;
      }

      if (type === "graph") {
        this.detailShow = false;
        this.fileListShow = false;
        this.detailGraph = true;
      }
      if (type === "file") {
        this.detailShow = false;
        this.fileListShow = true;
        this.detailGraph = false;
      }
    },
    setup(xAxisData, yAxisData) {
      this.option = {
        title: {
          text: "양생온도",
          left: "center",
          textStyle: {
            fontFamily: "Noto Sans Korean",
            fontWeight: "500",
            fontSize: 20,
            color: "#ffffff",
          },
        },

        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",

          data: xAxisData,
          boundaryGap: false,
        },
        yAxis: {
          max: 70,
          type: "value",
          // min: closestMultipleOfFive,
          axisLabel: {
            // Y축 폰트 크기
            fontSize: 11,
            color: "rgba(255, 255, 255, 0.5)",
          },
          splitLine: {
            // 차트 중간선 컬러
            lineStyle: {
              color: "rgba(255, 255, 255, 0.1)",
            },
          },
        },

        series: [
          {
            data: yAxisData,
            type: "line",
            name: "강도값",
            markLine: {
              data: [
                // { type: "average", name: "avg" },
                { yAxis: 0, name: "" },
                { yAxis: 5, name: "" },
                { yAxis: 20, name: "" },
                { yAxis: 40, name: "" },
                { yAxis: 60, name: "" },
                { yAxis: 70, name: "" },
              ],
              lineStyle: {
                color: "red", // markLine의 색상
                // width: 5, // markLine의 두께
                // type: "dashed", // markLine의 스타일 (실선, 점선 등)
              },
            },
          },
        ],
      };
    },
  },
};
</script>
<style scoped>
.cancel.on {
  background-color: rgb(0, 100, 255);
  pointer-events: none;
}
#container {
  height: 400px !important;
  width: 100% !important;
  display: flex;
}
/* .popup .foot_btns button.cancel:hover {
  background-color: rgb(0, 100, 255);
} */
</style>
