<template>
  <div v-show="isShow" class="dash_admin_box">
    <div style="display: block" class="dash_admin_box dash_commute_01">
      <!-- 검색 Wrap -->
      <div class="option_wrap">
        <div class="search_filter">
          <form>
            <select
              v-model="selectSiteId"
              class="search_option"
              @change="changeSite()"
            >
              <option
                v-for="item in accessSiteList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
            <div class="option_date">
              <date-picker
                id="startedDate"
                v-model="listParam.beginDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
              />
              <span>~</span>
              <date-picker
                id="endedDate"
                v-model="listParam.endDate"
                :lang="getLang"
                value-type="format"
                format="YYYY-MM-DD"
                type="date"
              />
            </div>

            <select
              v-model="firstCode"
              class="search_option"
              @change="codeChange()"
            >
              <option
                v-for="item in testItemList"
                :key="item.code"
                :value="item.code"
              >
                {{ item.name }}
              </option>
            </select>
            <select
              v-model="listParam.concreteType"
              class="search_option"
              @change="codeChange()"
            >
              <option
                v-for="item in concreteTypeList"
                :key="item.code"
                :value="item.code"
              >
                {{ item.name }}
              </option>
            </select>

            <div class="option_date w100">
              <input
                v-model="listParam.name"
                type="text"
                placeholder="확인자/감독관 검색"
                @keyup.enter="onClickSearch"
              />
            </div>

            <div class="search_button">
              <button type="button" @click="onClickSearch">검색</button>
            </div>
          </form>
        </div>
        <div class="right_area">
          <div class="download_button">
            <button type="button" @click="onClickExcel">엑셀 다운로드</button>
          </div>
        </div>
      </div>
      <!-- 검색 Wrap //-->

      <div class="dash_admin_box_table">
        <table>
          <thead>
            <tr>
              <th width="90">처리상태</th>
              <th width="90">현장명</th>
              <th width="90">타설일자</th>
              <th width="120">시험번호</th>
              <th width="100">구조물명</th>
              <th width="300">타설부위</th>
              <th v-if="listParam.concreteType === 'normal'">설계량</th>
              <th>타설량</th>
              <th width="120">배합종류</th>
              <th v-if="listParam.concreteType === 'normal'">납품회사</th>
              <th>타설방법</th>
              <th>타설시간</th>
              <th width="100">외부/Con`c</th>
              <th>슬럼프 (mm)</th>
              <th>공기량(%)</th>
              <th>염분량(㎏/㎥)</th>
              <th>단위수량</th>
              <th v-if="listParam.concreteType === 'shortCrete'">1일</th>
              <th>28일</th>
              <th v-if="listParam.concreteType === 'shortCrete'">휨강도</th>
              <th v-if="listParam.concreteType === 'shortCrete'">휨윈성</th>
              <th>확인자</th>
              <th>서명</th>
              <th>감독관</th>
              <th>서명</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="pageParam.total === 0">
              <td colspan="19">검색 결과가 없습니다.</td>
            </tr>
            <template v-if="pageParam.total !== 0">
              <tr v-for="(item, index) in pageParam.content" :key="index">
                <td>
                  {{ displayStatus(item.bookStatus) }}
                </td>
                <td>
                  {{ item.siteName }}
                </td>
                <td>
                  {{ item.pourDay }}
                </td>
                <td style="cursor: pointer; color: aqua" @click="detail(item)">
                  {{ item.testNo }}
                </td>
                <td>
                  {{ item.structureName }}
                </td>
                <td v-html="enter2Br(item.pourArea)" />

                <td v-if="listParam.concreteType === 'normal'">
                  {{ item.planQuantity }}
                </td>
                <td>
                  {{ item.actualQuantity }}
                </td>
                <td>
                  {{ item.specification }}
                </td>
                <td v-if="listParam.concreteType === 'normal'">
                  {{ item.company }}
                </td>
                <td>
                  {{ item.pourEquip }}
                </td>
                <td>
                  {{ sliceTime(item.beginTime) }} <br />
                  {{ sliceTime(item.endTime) }}
                </td>
                <td>
                  <p
                    v-for="(temperature, slumpsIdx) in item.temperatures"
                    :key="slumpsIdx"
                  >
                    {{ temperature }}
                  </p>
                </td>
                <td>
                  <p v-for="(slump, slumpsIdx) in item.slumps" :key="slumpsIdx">
                    {{ slump }}
                  </p>
                </td>
                <td>
                  <p v-for="(air, slumpsIdx) in item.airs" :key="slumpsIdx">
                    {{ air }}
                  </p>
                </td>
                <td>
                  <p
                    v-for="(chloride, slumpsIdx) in item.chlorides"
                    :key="slumpsIdx"
                  >
                    {{ chloride }}
                  </p>
                </td>
                <td>
                  <p
                    v-for="(unitQuantity, slumpsIdx) in item.unitQuantities"
                    :key="slumpsIdx"
                  >
                    {{ unitQuantity }}
                  </p>
                </td>
                <td v-if="listParam.concreteType === 'shortCrete'">
                  <p
                    v-for="(strength7, slumpsIdx) in item.strength7"
                    :key="slumpsIdx"
                  >
                    {{ strength7 }}
                  </p>
                </td>
                <td>
                  <p
                    v-for="(strength28, slumpsIdx) in item.strength28s"
                    :key="slumpsIdx"
                  >
                    {{ strength28 }}
                  </p>
                </td>
                <td v-if="listParam.concreteType === 'shortCrete'">
                  <p
                    v-for="(
                      strength28bending, slumpsIdx
                    ) in item.strength28bending"
                    :key="slumpsIdx"
                  >
                    {{ strength28bending }}
                  </p>
                </td>
                <td v-if="listParam.concreteType === 'shortCrete'">
                  <p
                    v-for="(
                      strength28toughness, slumpsIdx
                    ) in item.strength28toughness"
                    :key="slumpsIdx"
                  >
                    {{ strength28toughness }}
                  </p>
                </td>
                <td>
                  <span style="display: block">{{ item.checkerName }}</span>
                  <span style="display: block"
                    >{{ item.strengthCheckerName }}
                  </span>
                </td>
                <td>
                  <span style="display: block">
                    <img
                      v-if="
                        item.checkerFileId != null && item.checkerSignDt != null
                      "
                      :src="
                        FILE_URL +
                        'file_id=' +
                        item.checkerFileId +
                        '&file_no=2'
                      "
                      alt=""
                    />
                  </span>
                  <span style="display: block">
                    <img
                      v-if="
                        item.strengthCheckerFileId != null &&
                        item.strengthCheckerSignDt != null
                      "
                      :src="
                        FILE_URL +
                        'file_id=' +
                        item.strengthCheckerFileId +
                        '&file_no=2'
                      "
                      alt=""
                    />
                  </span>
                </td>
                <td>
                  <span style="display: block"> {{ item.supervisorName }}</span>
                  <span style="display: block">{{
                    item.strengthSupervisorName
                  }}</span>
                </td>
                <td>
                  <span style="display: block">
                    <img
                      v-if="
                        item.supervisorFileId != null &&
                        item.supervisorSignDt != null
                      "
                      :src="
                        FILE_URL +
                        'file_id=' +
                        item.supervisorFileId +
                        '&file_no=2'
                      "
                      alt=""
                    />
                  </span>
                  <span style="display: block">
                    <img
                      v-if="
                        item.strengthSupervisorFileId != null &&
                        item.strengthSupervisorSignDt != null
                      "
                      :src="
                        FILE_URL +
                        'file_id=' +
                        item.strengthSupervisorFileId +
                        '&file_no=2'
                      "
                      alt=""
                    />
                  </span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <pagination
        :page-param="pageParam"
        :list-cnt="listParam.size"
        @pageCallback="pageCallback"
      />
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import Pagination from "./layout/Pagination.vue";
import { getToday, toDayFormatAddDay, dayFormatNewDate } from "../utils/date";
import { mapGetters, mapActions } from "vuex";
import TestItemApi from "../api/qbox/TestItemApi";
import RemiconBookApi from "../api/qbox/RemiconBookApi";
import SiteApi from "../api/qbox/SiteApi";
import { QBOX_URL, FILE_URL, JWT_TOKEN } from "../const/index";
export default {
  name: "TestBookList",
  components: { Pagination, DatePicker },
  props: {
    siteId: {},
  },
  data: () => {
    return {
      companyList: [],
      pageParam: { content: [] },
      listParam: {
        page: 1,
        size: 20,
        beginDate: toDayFormatAddDay(-7),
        endDate: getToday(),
        name: null,
        concreteType: "normal",
      },
      testItemList: {},
      firstCode: null,
      accessSiteList: [],
      selectSiteId: null,
      concreteTypeList: [
        { code: "normal", name: "콘크리트" },
        { code: "shortCrete", name: "숏크리트" },
      ],
      isShow: false,
      FILE_URL: FILE_URL,
    };
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
  created() {},
  async mounted() {},
  beforeDestroy() {},
  methods: {
    getToday: getToday,
    dayFormatNewDate: dayFormatNewDate,
    ...mapActions(["showLoading", "hideLoading"]),
    async init() {
      this.selectSiteId = this.siteId;
      const response = await SiteApi.getSiteYearPlan(this.siteId);
      this.siteYearPlanList = response;
      if (this.siteYearPlanList.length > 0) {
        const today = this.getToday();
        const findSiteYear = this.siteYearPlanList.filter(
          (siteYear) => siteYear.beginDay <= today && siteYear.endDay >= today
        );
        if (findSiteYear.length > 0) {
          this.listParam.beginDate = findSiteYear[0].beginDay;
        } else {
          this.listParam.beginDate = toDayFormatAddDay(-7);
        }
      } else {
        const today = new Date();
        this.listParam.beginDate = this.dayFormatNewDate(
          new Date(today.getFullYear(), 0, 1)
        );
      }
      this.getSiteList();
      this.fetchTestBook();
      this.testItem();
    },
    async getSiteList() {
      const response = await SiteApi.getSiteList();
      this.accessSiteList = response;
      console.log(this.accessSiteList);
    },
    onClickSearch() {
      this.listParam.page = 1;
      this.fetchTestBook();
    },
    testItem() {
      TestItemApi.getDepthList(1, this.siteId).then((res) => {
        console.log(res);
        this.testItemList = res;
        const defaultCode = { name: "공종전체", id: null, code: null };
        this.testItemList.unshift(defaultCode);
      });
    },
    async fetchTestBook() {
      //TODO 구해야 됨
      this.listParam.siteId = this.selectSiteId;
      this.listParam.itemCode = this.firstCode;
      this.showLoading();
      const response = await RemiconBookApi.remiconBookList(this.listParam);
      response.size = this.listParam.size;
      this.pageParam = response;
      this.pageParam.content.forEach((item) => {
        this.accessSiteList.forEach((site) => {
          if (item.siteId === site.id) {
            item.siteName = site.name;
          }
        });
      });
      this.hideLoading();
    },
    changeSite() {
      this.fetchTestBook();
    },
    pageCallback(page) {
      this.listParam.page = page;
      this.fetchTestBook();
    },
    contentSubStr(str) {
      if (str) {
        str = str.replace(/<[^>]*>?/g, "");
        if (str.length > 10) {
          str = str.substr(0, 8) + "...";
        }
      }
      return str;
    },
    imgFaults(item) {
      var url = "/img/common/";
      if (item) url += "bullet_red_icon.png";
      else url += "bullet_green_icon.png";

      return url;
      //bullet_green_icon.png
      //bullet_red_icon.png
    },
    async onClickExcel() {
      const url = "/remicon/excel";
      this.showLoading();
      await RemiconBookApi.excelDown(url, this.listParam);
      this.hideLoading();
      //엑셀 다운로드 구현
    },
    getNumber(index) {
      this.pageParam.total -
        ((this.listParam.page - 1) * this.listParam.size + index);
    },
    codeChange() {
      this.pageParam = { content: [] };
      this.listParam.itemCode = this.firstCode;
      this.fetchTestBook();
    },
    sliceTime(time) {
      if (time) return time.slice(0, 5);
      return "";
    },
    enter2Br(str) {
      if (!str) return "";
      return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
    displayStatus(status) {
      if (status === "approval") return "결재완료";
      else return "결재대기";
    },
    open() {
      this.init();
      this.isShow = true;
    },
    close() {
      this.listParam.beginDate = toDayFormatAddDay(-7);
      this.listParam.endDate = getToday();
      this.listParam.name = null;
      this.isShow = false;
    },
    detail(item) {
      var popupW = 1200;
      var popupH = 800;
      var left = Math.ceil((window.screen.width - popupW) / 2);
      var top = Math.ceil((window.screen.height - popupH) / 2);
      const token = localStorage.getItem(JWT_TOKEN);

      const options =
        "toolbar=no,scrollbars=no,resizable=yes,status=no,menubar=no,width=1200, height=800, top=" +
        top +
        ",left=" +
        left;
      window.open(
        `${QBOX_URL}/testBookRouter/${item.testBookId}?isPopup=1&token=${token}`,
        "_blank",
        options
      );
    },
  },
};
</script>
